import { Button } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useBulkUpdateDevices } from '~/api/devices';
import {
  Dialog,
  DialogContentTitle,
  DialogForm,
  DialogTitle,
} from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import type { DeviceBulkSelectionInput, DeviceNightlyBehavior } from '~/generated/graphql';
import { pluralize } from '~/lib/string';
import type {
  DeviceList__Channel as Channel,
  DeviceList__DeviceGroup as DeviceGroup,
} from '../../queries/list.generated';
import { BulkUpdateConfirmDialog } from './BulkSettingsConfirmationDialog';
import {
  BulkDeviceSettingsForm,
  bulkDeviceSettingsValidationSchema,
  useBulkDeviceSettingsFormik,
} from './BulkSettingsForm';

interface BulkDeviceSettingsDialogProps {
  deviceGroups: ReadonlyArray<DeviceGroup>;
  channels: ReadonlyArray<Channel>;
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
  selected: DeviceBulkSelectionInput;
  selectedDeviceCount: number;
}

export interface DeviceBulkPatchInput {
  defaultChannelId?: number | null;
  demo?: boolean;
  deviceGroupId?: number | null;
  eventStream?: boolean;
  internal?: boolean;
  nightlyBehavior?: DeviceNightlyBehavior;
  overscan?: number | null;
  realtime?: boolean;
  screenshotInterval?: string | null;
  silence?: boolean;
}

export const BulkDeviceSettingsDialog = ({
  channels,
  deviceGroups,
  onClose,
  onComplete,
  open,
  selected,
}: BulkDeviceSettingsDialogProps) => {
  const [updateDevices, { loading }] = useBulkUpdateDevices();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const initialValues = {
    defaultChannelId: undefined,
    demo: undefined,
    deviceGroupId: undefined,
    eventStream: undefined,
    internal: undefined,
    nightlyBehavior: undefined,
    overscan: undefined,
    realtime: undefined,
    screenshotInterval: undefined,
    silence: undefined,
  };

  const formik = useBulkDeviceSettingsFormik(initialValues, () => {
    setConfirmOpen(true);
  });

  const patch = useMemo(
    () => bulkDeviceSettingsValidationSchema.cast(formik.values),
    [formik.values],
  );

  const handleClose = useCallback(() => {
    formik.resetForm();
    onClose();
  }, [formik, onClose]);

  return (
    <>
      <DialogForm
        onSubmit={(event) => {
          event.preventDefault();
          setConfirmOpen(true);
        }}
      >
        <Dialog
          aria-labelledby="settings-dialog-title"
          maxWidth="sm"
          fullWidth
          onClose={handleClose}
          open={open}
        >
          <DialogTitle id="settings-dialog-title" onClose={handleClose}>
            Bulk Update
          </DialogTitle>
          <DialogContent>
            <DialogContentTitle>
              Update the settings for {selected.ids?.length ?? 'all selected'}{' '}
              {pluralize('Device', selected.ids?.length ?? 2)}
            </DialogContentTitle>
            <BulkDeviceSettingsForm
              formik={formik}
              deviceGroups={deviceGroups}
              channels={channels}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" disabled={loading}>
              Cancel
            </Button>
            <Button
              onClick={formik.submitForm}
              color="primary"
              variant="contained"
              disabled={loading || !formik.dirty || Object.keys(patch).length === 0}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </DialogForm>
      {confirmOpen && (
        <BulkUpdateConfirmDialog
          open={confirmOpen}
          onConfirm={async () => {
            try {
              await updateDevices({
                variables: {
                  input: {
                    patch,
                    selected,
                  },
                },
              });
              formik.resetForm();
              onComplete();
              onClose();
            } catch (error) {
              console.error(error);
            }
          }}
          onCancel={() => {
            setConfirmOpen(false);
            formik.setSubmitting(false);
          }}
          patch={patch}
          selectedDeviceCount={selected.ids ? selected.ids.length : 0}
          channels={channels}
          deviceGroups={deviceGroups}
          loading={loading}
        />
      )}
    </>
  );
};
