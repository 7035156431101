import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import {
  Dialog,
  DialogContentTitle,
  DialogForm,
  DialogTitle,
} from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { capitalize, pluralize } from '~/lib/string';
import type {
  DeviceList__Channel as Channel,
  DeviceList__DeviceGroup as DeviceGroup,
} from '../../queries/list.generated';
import type { DeviceBulkPatchInput } from './BulkSettingsDialog';

interface BulkUpdateConfirmDialogProps {
  channels: ReadonlyArray<Channel>;
  deviceGroups: ReadonlyArray<DeviceGroup>;
  loading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  patch: DeviceBulkPatchInput;
  selectedDeviceCount: number;
}

interface PatchEntry {
  label: string;
  element: JSX.Element;
}

export const BulkUpdateConfirmDialog = ({
  channels,
  deviceGroups,
  loading,
  onConfirm,
  onCancel,
  open,
  patch,
  selectedDeviceCount,
}: BulkUpdateConfirmDialogProps) => {
  const fieldMappings: {
    [key in keyof DeviceBulkPatchInput]: {
      label: string;
      formatValue: (value: DeviceBulkPatchInput[key] | undefined) => string;
    };
  } = {
    defaultChannelId: {
      label: 'Default Channel',
      formatValue: (value) => {
        const channel = channels.find((c) => c.id === value);
        return channel
          ? `${channel.name} ${channel.number ? ` (${channel.number})` : ''}`
          : 'No Default Channel';
      },
    },
    demo: {
      label: 'Demo',
      formatValue: (value) => (value ? 'Yes' : 'No'),
    },
    deviceGroupId: {
      label: 'Device Group',
      formatValue: (value) => {
        const group = deviceGroups.find((g) => g.id === value);
        return group ? group.name : 'No Device Group';
      },
    },
    eventStream: {
      label: 'Event Stream',
      formatValue: (value) => (value ? 'Yes' : 'No'),
    },
    internal: {
      label: 'Internal',
      formatValue: (value) => (value ? 'Yes' : 'No'),
    },
    nightlyBehavior: {
      label: 'Nightly Reboot/Restart',
      formatValue: (value) => (value ? capitalize(value) : 'No Change'),
    },
    overscan: {
      label: 'Overscan',
      formatValue: (value) => (value ? `${value}%` : 'No Change'),
    },
    realtime: {
      label: 'Realtime',
      formatValue: (value) => (value ? 'Yes' : 'No'),
    },
    screenshotInterval: {
      label: 'Automatic Screenshots',
      formatValue: (value) => {
        const intervals: { [key: string]: string } = {
          PT1M: '1 minute',
          PT2M: '2 minutes',
          PT5M: '5 minutes',
          PT10M: '10 minutes',
          PT15M: '15 minutes',
          PT30M: '30 minutes',
          PT60M: '60 minutes',
        };
        if (value === null) return 'Disable';
        if (value === undefined) return 'No Change';
        return intervals[value] || 'Unknown';
      },
    },
    silence: {
      label: 'Silence',
      formatValue: (value) => (value ? 'Yes' : 'No'),
    },
  };

  const patchEntriesArray = Object.entries(patch)
    .map(([key, value]): PatchEntry | null => {
      const mapping = fieldMappings[key as keyof DeviceBulkPatchInput];
      if (!mapping) return null;

      const label = mapping.label;
      const displayValue = mapping.formatValue(value);

      return {
        label,
        element: (
          <Box key={key} mb={2}>
            <label>
              <span className="label" style={{ fontWeight: 'bold' }}>
                {label}
              </span>
              <span>{displayValue}</span>
            </label>
          </Box>
        ),
      };
    })
    .filter((entry): entry is PatchEntry => entry !== null);

  const sortedPatchEntriesArray = patchEntriesArray.sort((a, b) => a.label.localeCompare(b.label));

  const patchEntries = sortedPatchEntriesArray.map((entry) => entry.element);

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onCancel}>Bulk Update</DialogTitle>
      <DialogContent>
        <DialogContentTitle>
          Are you sure you want to update{' '}
          {selectedDeviceCount > 0 ? selectedDeviceCount : 'all selected'}{' '}
          {selectedDeviceCount > 0 ? pluralize('Device', selectedDeviceCount) : 'Devices'} with the
          following changes:
        </DialogContentTitle>
        <DialogForm>{patchEntries}</DialogForm>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          loadingPosition="start"
          variant="contained"
          color="primary"
          loading={loading}
          startIcon={<Save />}
          onClick={onConfirm}
        >
          Apply Changes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
